.slider {
    img {
        width: 100%;
        height: auto;
        display:block;
    }

    &.team {
        img {
            max-height:calc(100vh - 90px) !important;
            object-fit:cover !important;
        }
    }

    .flexslider {
        border:none;
        border-radius:0;
        box-shadow:none;
        margin-bottom:0;
    }
}

// Image carousel next/prev arrows
.vc_images_carousel .vc_carousel-control{

}

.case-study-carousel{
    .vc_carousel-inner {
        .vc_item {
            .vc_inner {
                img {
                    width: 100%;
                    height: auto;
                    display:block;
                }
            }
        }
    }

    .vc_carousel-control {
        width: 45px !important;
        height: 45px !important;
        text-shadow:none !important;
        font-size: 45px !important;
        margin-top:0px !important;
        line-height: normal !important;
        opacity: 0.8 !important;

        &.vc_left {
            top: auto;
            right: -45px !important;
            bottom: -1px !important;
            left: auto !important;
            background-color:#fff !important;

            .icon-prev {
                font-family: 'vc_grid_v1' !important;
                font-size: 45px !important;
                width: 45px !important;
                height: 45px !important;
                margin:0  !important;
                top:0px  !important;
                left:-1px  !important;
                background-color:transparent !important;
                opacity: 0.8 !important;
                transition: opacity 0.3s ease-in 0s !important;

                &:hover {
                    opacity: 1 !important;
                }

                &::before {
                    content:'\e610' !important;
                    color:#888370;
                    top:0px;
                    opacity:1 !important;
                }
            }
        }

        &.vc_right {
            top: auto !important;
            right: -94px !important;
            bottom: -1px !important;
            left: auto !important;

            .icon-next {
                font-family: 'vc_grid_v1' !important;
                font-size: 45px !important;
                width: 45px !important;
                height: 45px !important;
                margin:0  !important;
                top:0px  !important;
                left:0  !important;
                background-color:transparent !important;
                opacity: 0.8 !important;
                transition: opacity 0.3s ease-in 0s !important;

                &:hover {
                    opacity: 1 !important;
                }

                &::before {
                    content:'\e611' !important;
                    color:#888370;
                    top:0px;
                    opacity:1 !important;
                }
            }
        }
    }
}

@import "_home-slider";
@import "_blog-slider";
@import "_testimonial-slider";