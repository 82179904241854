// FIND YOUR HOME
.icon-group-section {

    background-repeat:no-repeat;
    background-position: right center;

    &.screen-one {
        background-image:url("/wp-content/themes/development-site/assets/images/screen-one.png");

        @media (max-width: 1500px) {
            background-size:16% auto;
        }
    
        @include media-breakpoint-down(lg) {
            background-size:15% auto;
        }
    
        @include media-breakpoint-down(md) {
            background-image: none;
        }
    }

    &.screen-two {
        background-image:url("/wp-content/themes/development-site/assets/images/screen-two.png");

        @media (max-width: 1500px) {
            background-size:16% auto;
        }
    
        @include media-breakpoint-down(lg) {
            background-size:15% auto;
        }
    
        @include media-breakpoint-down(md) {
            background-image: none;
        }
    }

    .icon-group-section-row {
        position:relative;

        &.padding-row {

            @include media-breakpoint-down(md) {
                padding:4rem 60px;
            }
        
            @include media-breakpoint-down(sm) {
                padding:3rem 30px;
            }
        }

        .icon-group-section-item{
            height: 120px;
            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
            }
        }

        h2, .h2 {    
            position: relative;
            top:0px;
            left:15px;
            margin-bottom: 90px;

            @include media-breakpoint-down(sm) {
                position:relative;
                margin-bottom: 3rem;
            }
        }

        .icon {
            width:70px;
            height:auto;

            .wpb_content_element {
                margin-bottom:0 !important;
            }
        }

        .description {
            padding:30px 15% 30px 0px;
    
            @include media-breakpoint-down(sm) {
                padding:0;
            }

            h6, .h6 {
                font-weight:500;
                font-size: 16px;
            }

            p {
                font-size: 0.85rem;

                &:last-of-type {
                    margin-bottom:0px;
                }
            }
        }
    }
}