// PARALLAX
.parallax {
    background-position: center center;
    min-height:500px;
    height:1px;

    @include media-breakpoint-down(sm) {
        height:calc(100vh - 50px);
    }

    .parallax-container {
        height:100%;
        color:#fff;
        font-size:18px;
        padding:15px;

        h1, .h1 {
            font-size:41px;
            margin-bottom:1rem;
            line-height:44px;
        }

        h1, .h1, p, div, span {
            color:#fff;
        }

        a {
            color:#fff;
        }

        .btn {
            font-size:18px;
        }

        .vc_column-inner {
            margin:0 auto;
            width:auto !important;
        }
    }

    &:after {
        content: ' ';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(77,79,100,0.64);
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        background-blend-mode: overlay;
    }
}