.testimonial-slider {
    
    .vc_grid-item{
        width: 100% !important;
    }
    
    .vc_grid.vc_grid-owl-theme .vc_grid-owl-nav {
        @media only screen and (min-width: 767px) {
            .vc_grid-owl-next, .vc_grid-owl-prev{
                font-size:100px;
            }
        }
        @media only screen and (max-width: 767px) {
            .vc_grid-owl-next, .vc_grid-owl-prev{
                font-size:50px;
            }
        }
    }
    .vc_grid.vc_grid-owl-theme .vc_grid-owl-nav {
        opacity: 100;
    }
    .vc_arrow-icon-arrow_10_left:before, .vc_arrow-icon-arrow_10_right:before {
        background-color: white;
    }

    &.slider-overlay{
        background: #373738;
        color: white !important;
        .owl-carousel {
            opacity: .8;
        }

        .caption{
            h1, a{
                color: white !important;
            }
        }
    }
}

.testimonial-details-slider{
    
	.vc_grid{
    }

    .vc_grid-item{
    }

    .vc_gitem_row {
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color:#fff;
    }

    .owl-carousel {
        margin-bottom:-15px !important;
    }

    .owl-item {
    }

    .owl-stage-outer, .vc_gitem-animated-block .vc_gitem-zone{
        //min-height:400px;
        background-color:#F4F4F4;
    }

    .vc_gitem-row-position-middle {
        position:relative;
    }

    .vc_gitem-animated-block{
    }

    .vc_gitem-zone {
        overflow:visible;
    }

    .vc_grid.vc_grid-owl-theme {

        .vc_grid-owl-nav {
            display:block;
            width:95px;
            height:43px;
            opacity: 1;

            @media only screen and (max-width: 450px) {
                display: none;
            }

            .vc_grid-owl-next {
                right:0;
            }

            .vc_grid-owl-prev {
                left:0;
            }

            @media only screen and (min-width: 450px) {
                .vc_grid-owl-next, .vc_grid-owl-prev{
                    font-size:45px;
                }
            }

            @media only screen and (min-width: 767px) {
                position: absolute;
                bottom: 0;
                right:120px;
                color: white;
            }

            @media only screen and (max-width: 767px) {
                text-align: center;
            }
        }
    }

    .vc_arrow-icon-arrow_09_left:before, .vc_arrow-icon-arrow_09_right:before {
        color: $secondary;
    }
}

.testimonial-details-slider-excerpt{
    padding:70px 40px 70px 80px !important;

    @include media-breakpoint-down(md) {
        padding:70px 80px 20px 80px !important;
    }

    .testimonial-copy {
        display: flex;
        align-items: center;
        margin-bottom:40px;
    }

    .testimonial-author {
        position:relative;
        margin-bottom:0;
        text-transform: uppercase;
        padding-bottom:7px;

        &::after {
            content:'';
            display:block;
            width:112%;
            margin-top:4px;
            height:1px;
            background-color:#707070;
            position:absolute;
            left:-95px;
            bottom:0px;
        }

        &::before {
            content: url('/wp-content/themes/development-site/assets/images/quotes.png');
            display:inline-block;
            width:auto;
            position:absolute;
            left:-40px;
            bottom:6px;
        }
    }

    p{
        font-size: 14px;
    }
}

.testimonial-image {
    padding:70px 0px 70px 0px !important;

    @include media-breakpoint-down(md) {
        padding:20px 0px 80px 0px !important;
    }

    .wpb_single_image {
    }
}