// import google roboto font
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i');

@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css');

// import variables
@import '_variables';

// import bootrap
@import 'node_modules/bootstrap/scss/bootstrap';

@font-face {
    font-family: "FrankRuhlLibre";
    font-style: normal;
    src: url(/wp-content/themes/sales-site/assets/fonts/FrankRuhlLibre/FrankRuhlLibre-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "FrankRuhlLibre-Black";
    font-style: black;
    src: url(/wp-content/themes/sales-site/assets/fonts/FrankRuhlLibre/FrankRuhlLibre-Black.ttf) format("truetype");
}

@font-face {
    font-family: "FrankRuhlLibre-Bold";
    font-style: Bold;
    src: url(/wp-content/themes/sales-site/assets/fonts/FrankRuhlLibre/FrankRuhlLibre-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "FrankRuhlLibre-Light";
    font-style: light;
    src: url(/wp-content/themes/sales-site/assets/fonts/FrankRuhlLibre/FrankRuhlLibre-Light.ttf) format("truetype");
}

@font-face {
    font-family: "FrankRuhlLibre-Medium";
    font-style: medium;
    src: url(/wp-content/themes/sales-site/assets/fonts/FrankRuhlLibre/FrankRuhlLibre-Regular.ttf) format("truetype");
}

.frankRuhlLibre {
    font-family: "FrankRuhlLibre", Times, sans-serif;
}

.roboto {
    font-family: 'Roboto', sans-serif;
}

.mb-30, .my-30 {
    margin-bottom: 1.875rem !important;
}

.mb-2rem {
    margin-bottom:2rem;
}

h1, .h1 {
    //font-family: "FrankRuhlLibre-Medium";
    font-size:50px;
    color:$secondary;

    @include media-breakpoint-down(md) {
        font-size:35px;
    }     
}

h2, .h2 {
    font-weight:400;
    margin-bottom:1rem;
}

.bg-gray {
    background-color:#F4F4F4;
}

.bg-overlay {
    background-color:$overlay-bg;
}

// import custom styling
@import 'custom/_custom';
@import 'sliders/_sliders';
@import 'pages/_pages';
@import 'containers/_container';

// Step Box List
.step-box-list {
    padding-left: 30px;
    padding-right: 30px;

    .row > [class*=col] {
        margin-bottom:30px;
    }

    .card {
        border-radius: 0; border:none;
        margin-bottom:30px;

        .card-img-top {
            border-radius: 0;
        }

        .card-body {
            margin-top:20px;

            .card-step {
                background: #F4F4F4;
                border-radius: 1em;
                -moz-border-radius: 1em;
                -webkit-border-radius: 1em;
                display: inline-block;
                font-weight: bold;
                line-height: 2em;
                text-align: center;
                width: 2em;
            }

            .card-title {
                font-family: 'FrankRuhlLibre-Medium';
                font-size:24px;
            }

            .card-text {
                font-size:16px;
            }
        }

        .card-footer {
            border-radius:none;
            border:none;
            background-color:transparent;
        }
    }
}

// BLOG LIST
.blog-list {
    padding-bottom:3rem;

    .figure {
        margin-bottom:30px;

        a.figure-link {
            display:block;
            overflow:hidden;
            background-color:$primary;

            img {
                display:block;
                margin-bottom:0px;
                transform: scale(1.12);
                @include transition($transition-base);

                @include hover-focus {
                    transform: scale(1);
                    opacity:0.5;
                } 
            }
        }

        .figure-caption {
            font-size:inherit;
            color:$gray-900;
            padding: 12px 20px;
        }

        .figure-name {
            border: 1px solid $secondary;
            padding: 12px 20px;
            font-family: 'FrankRuhlLibre-Medium';
            font-size:16px;
            color:$secondary;
        }

        .figure-btn {
            text-transform:uppercase;
            font-size:12px;
            font-weight:500;
            padding:0px;
            line-height:48px;
        }
    }
}

// TEAM LIST
.team-list {
    padding-bottom:3rem;

    .figure {
        margin-bottom:30px;

        a.figure-link {
            display:block;
            overflow:hidden;
            background-color:$primary;

            img {
                width: 100% !important;
                height: auto !important;
                display:block;
                margin-bottom:0px;
                transform: scale(1.12);
                @include transition($transition-base);

                @include hover-focus {
                    transform: scale(1);
                    opacity:0.5;
                } 
            }
        }

        .figure-caption {
            font-size:inherit;
            color:$gray-900;
            padding: 12px 5%;
        }

        .figure-name {
            border: 1px solid $secondary;
            border-right:0;
            padding: 12px 5%;
            font-size:16px;
            color:$black;
            font-weight:bold;
        }

        .figure-btn {
            text-transform:uppercase;
            font-size:12px;
            font-weight:500;
            padding:0px;
            line-height:48px;
        }
    }
}

.bio-details {
    .social-icons {
        a {
            display:inline-block;
            margin-right:5px;
    
            img {
                width:35px;
                height:35px;
            }
        }
    }

    .author-name {
        padding-right:10%;

        h1, .h1 {
            word-break:break-word;
            line-height:1.0;
        }
    }
}

.bio-favorites {
    background-image: url(/wp-content/themes/development-site/assets/images/2701Hubbard.jpg);
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center;
    -webkit-box-shadow: inset 0 0 0 1000px rgba(98,101,125,0.46);
    box-shadow: inset 0 0 0 1000px rgba(98,101,125,0.46);
    padding: 193px 40px;
    color:#fff;
    font-size:16px;

    @include media-breakpoint-down(sm) {
        padding: 100px 40px 50px 40px;
    }

    .favorite-container {
        padding-left:100px;
        padding-right:100px;

        @include media-breakpoint-down(lg) {
            padding-left:60px;
            padding-right:60px;
        }

        @include media-breakpoint-down(md) {
            padding-left:40px;
            padding-right:40px;
        }

        @include media-breakpoint-down(sm) {
            padding-left:20px;
            padding-right:20px;
            margin-bottom:50px;
        }
    }
}

// Development LIST
.development-list {
    padding-left: 30px;
    padding-right: 30px;

    .figure {
        border-bottom:1px solid #000;

        a.figure-link {
            display:block;
            overflow:hidden;
            background-color:$primary;

            img {
                display:block;
                margin-bottom:0px;
                transform: scale(1.12);
                @include transition($transition-base);

                @include hover-focus {
                    transform: scale(1);
                    opacity:0.5;
                } 
            }
        }

        .caption {
            text-align:left;
            padding-top:15px;

            .name {
                font-size:24px;
                font-weight:bold;
            }

            .description {
                font-size:16px;
            }

            .explore {
                margin-top:10px;
                text-transform:uppercase;
                font-weight:600;
            }
        }
    }
}

.ihomefinder-fixed-menu {
    background-color:#fff;
    border-top: 1px solid #cdcdcd;
    -webkit-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.25);
    -moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.25);
    box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.25);
}


.page-item{
    .page-link.current{
        color: white;
        background-color: $secondary;
        border-color: $secondary;
    }
}

.display-overflow{
    overflow: visible !important;
}

.three-column-text-boxes {
    .inner-row {
        padding:0 0 3rem 0;

        &:last-child {
            padding:0 0 0 0;
        }

        @include media-breakpoint-down(sm) {
            padding:1rem 30px 0 30px;

            &:last-child {
                padding:1rem 30px 0 30px;
            }
        }
    }
    .text-box {
        .vc_column-inner {
            padding:0 3rem 1rem 3rem;

            @include media-breakpoint-down(sm) {
                padding:0 0 3rem 0;
            }

            h3 {
                font-size:16px;
                color:$secondary;
                margin-bottom:30px;
            }

            .copy {
                font-size:14px;
            }
        }
    }

    &.client-headlines {
        .text-box {
            .vc_column-inner {
                padding:0 3rem 1rem 3rem;
    
                @include media-breakpoint-down(sm) {
                    padding:0 0 3rem 0;
                }
    
                h3 {
                    font-size:16px;
                    color:$secondary;
                    margin-bottom:15px;
                }
    
                .copy {
                    font-size:14px;
                    margin-bottom:3rem;
                }
            }
        }
    }
}

.pie-chart {
    margin-bottom:20px !important;

    .vc_pie_wrapper {
        .vc_pie_chart_back {
            border: 12px solid #f7f7f7;
        }

        .vc_pie_chart_value {
            top:-15px;
            font-weight:bold;

            @include media-breakpoint-down(lg) {
                font-size:16px;
                top:-8px;
            }

            @include media-breakpoint-down(sm) {
                font-size:24px;
            }
        }
    }
}

.pie-chart-row {
    padding:4.5rem 70px;

    @include media-breakpoint-down(md) {
        padding:4rem 40px;
    }

    @include media-breakpoint-down(sm) {
        padding:3rem 15px;
    }

    &.pie-chart-bg {
        background-image: url('/wp-content/themes/development-site/assets/images/screen-pie.png');
        background-position: right center !important;
        background-repeat:no-repeat;
        background-size:auto;
    }

    .pie-chart-column {
        padding:2rem 30px;

        @include media-breakpoint-down(lg) {
            padding:2rem 20px;
        }

        @include media-breakpoint-down(md) {
            padding:2rem 10px;
        }

        @include media-breakpoint-down(sm) {
            padding:3rem 5rem;
        }
    }
}

.pie-chart-average {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    color:$secondary;
    font-size:16px;
    margin-top:5px;

    @include media-breakpoint-down(lg) {
        top: 48%;
        font-size:14px;
    }

    @include media-breakpoint-down(sm) {
        font-size:18px;
    }
}

.image-group-row {
}

.img-group {
    
    .vc_figure {
        width:100%;
    }
    .vc_single_image-wrapper {
        width:100%;
    }
    .wpb_single_image {
        img {
            height:fit-content;
        }
    }

    img {
        object-fit: cover;
        //height: fit-content !important;
        width:100%;
    }

    &.left-image {
        position:relative;
        z-index:11;
        margin-right:-17%;

        @include media-breakpoint-down(sm) {
            //margin-right:0;
        }
    }
}

.left-image-column {
    position:relative;
    z-index:10;
    padding-left:100px !important;

    @include media-breakpoint-down(md) {
        padding-left:60px !important;
    }

    @include media-breakpoint-down(sm) {
        padding-left:30px !important;
    }
}

.page-header {
    padding:1rem 100px 4.5rem 100px;

    @include media-breakpoint-down(md) {
        padding:1rem 60px 4rem 60px;
    }

    @include media-breakpoint-down(sm) {
        padding:1rem 30px 3rem 30px;
    }

    .page-heading {

        h1 {
            color:$gray-900;
            font-size:34px;
            margin-bottom:0px;
            line-height: 1.1;
        }

        .parent-page {
            display:flex;
            align-items:center;
            font-size:16px;
            font-weight:bold;
            color:$secondary;
            margin-bottom:20px;

            &::after {
                content:"";
                flex: 1;
                margin-left: 1rem;
                height: 1px;
                background-color: #707070;
            }
        }
    
        @include media-breakpoint-down(sm) {
            margin-bottom:20px;
        }
    }

    .header-copy {
        font-size:14px;
        padding:0 0 0 4rem;

        @include media-breakpoint-down(md) {
            padding:0 0 0 3rem;
        }
    
        @include media-breakpoint-down(sm) {
            padding:0;
        }
    }
}

.percentages-with-copy {
    
    .list-group-column {
        @include media-breakpoint-down(sm) {
            margin-bottom:30px;
        }

        .list-group-div {
            .value {
                font-size:24px;
                font-weight:bold;
                color:$secondary;
            }
            .description {
                font-size:16px;
                margin-left:40px;
        
                @include media-breakpoint-down(sm) {
                    margin-left:0;
                }
            }
        }
    }
    
    .copy {
        font-size:28px;
        line-height:32px;
        border-right:4px solid $secondary;
        padding:2rem 1rem 2rem 8rem;
        
        @include media-breakpoint-down(md) {
            padding:2rem 1rem 2rem 6rem;
        }
        
        @include media-breakpoint-down(sm) {
            padding:2rem 6rem 2rem 6rem;
        }
    }
}

.image-w-copy-two-columns {
    padding:3.5rem 100px 4.5rem 0;
    box-shadow: 0 6px 15px -15px black;

    @include media-breakpoint-down(md) {
        padding:2.5rem 60px 4rem 0;
    }

    @include media-breakpoint-down(sm) {
        padding:1.5rem 0 3rem 0;
    }

    .image {

        img {
            margin-left:-15px;

            @include media-breakpoint-down(sm) {
                margin-left:0px;
            }
        }
    }

    .copy {
        font-size:28px;
        line-height:32px;
        border-right:4px solid $secondary;
        padding:2rem 1rem 2rem 4.5rem;
        
        @include media-breakpoint-down(md) {
            padding:2rem 1rem 2rem 3rem;
        }
        
        @include media-breakpoint-down(sm) {
            padding:2rem 1rem 2rem 3.5rem;
            margin-right:3.5rem;
        }
    }

    .image-copy {
        padding:3.5rem 0 0 100px;
        margin-right: -15px;
    
        @include media-breakpoint-down(md) {
            padding:2.5rem 0 0 40px;
        }
    
        @include media-breakpoint-down(sm) {
            padding:1.5rem 30px 0 30px;
            margin-bottom:30px;
        }
    }
}

.Case-Study-Teaser {

    .right-side-image {
        //max-height:700px;
        overflow:hidden;

        .vc_figure {
            width:100%;
        }
        .vc_single_image-wrapper {
            width:100%;
        }
        .wpb_single_image {
            img {
                height:fit-content;
            }
        }
    
        img {
            object-fit: cover;
            //height: fit-content !important;
            width:100%;
        }
    }

    .case-study-info {
        width:30%;
        .vc_column-inner {
            padding: 5rem !important;
            background-position:bottom right !important;
            background-size: auto 60% !important;
    
            @include media-breakpoint-down(md) {
                background-size: auto 60% !important;
                padding: 1rem !important;
            }
    
            @include media-breakpoint-down(sm) {
            }

            .wpb_wrapper {
                margin:auto 0;

                .copy {
                    font-size:24px;
                    line-height:30px;
                    font-weight:bold;
                    color:$white;

                    a {
                        display:inline-block;
                        margin-top:50px;
                        font-size:14px;
                        color:$white;
                    }

                    @include media-breakpoint-down(md) {
                        font-size:20px;
                        line-height:22px;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size:18px;
                        line-height:20px;
                    }
                }
            }
        }
    }

    .left-side {
        display:flex;
        flex-direction:row;
        flex-grow:1;
        width:20%;

        .vc_column-inner {
            .wpb_wrapper {
                display:flex;
                flex-direction:column;
                flex-grow:1;
            }
        }
    }

    .left-side-top-image {
    
        max-height:700px;
        overflow:hidden;

        .vc_figure {
            width:100%;
        }
        .vc_single_image-wrapper {
            width:100%;
        }
        .wpb_single_image {
            img {
                height:fit-content;
            }
        }
    
        img {
            object-fit: cover;
            //height: fit-content !important;
            width:100%;
        }
    }

    .pie-chart-div {
        display:flex !important;
        flex-direction:row !important;
        flex-grow:1 !important;
        
        .vc_pie_wrapper {
            .vc_pie_chart_value {
                @include media-breakpoint-down(xs) {
                    font-size:40px;
                    height:75px !important;
                    line-height:122px !important;
                }
            }
        }

        .vc_pie_chart_back {
            @include media-breakpoint-down(xs) {
                display:none;
                height:75px;
            }
        }

        canvas {
            @include media-breakpoint-down(xs) {
                visibility: hidden;
                height:75px;
            }
        }
    
        .pie-chart-column {
            padding:2rem;
    
            @include media-breakpoint-down(lg) {
                padding:1rem;
            }
    
            @include media-breakpoint-down(md) {
                //padding:2rem 10px;
            }
    
            @include media-breakpoint-down(sm) {
                padding:1.5rem 30px;
            }
    
            @include media-breakpoint-down(sm) {
                padding-bottom:3rem;
            }
        }
    }
    
    .pie-chart-average {
        text-align: center;
        position: absolute;
        top: 47%;
        left: 0;
        width: 100%;
        color:$secondary;
        font-size:16px;
        margin-top:5px;

        @include media-breakpoint-down(lg) {
            top: 40%;
            font-size:14px;
        }

        @include media-breakpoint-down(sm) {
            top: 50%;
            font-size:18px;
        }

        @include media-breakpoint-down(xs) {
            top: 50%;
            font-size:16px;
            display:none;
        }
    }
}

.number-bordered-list {
    padding:4.5rem 100px 4.5rem 100px;

    @include media-breakpoint-down(md) {
        padding:4rem 60px 4rem 60px;
    }

    @include media-breakpoint-down(sm) {
        padding:3rem 30px 3rem 30px;
    }

    .number {
        font-size:24px;
        color:$secondary;
    }

    .copy {
        font-size:16px;
    }
}

// blog post page
.blog-details-header {
    .blog-title {
        padding:10rem;
        
        @include media-breakpoint-down(lg) {
            padding:7rem;
        }
        
        @include media-breakpoint-down(md) {
            padding:5rem;
        }
        
        @include media-breakpoint-down(sm) {
            padding:3rem;
        }

        .title {
            font-size:28px;
            font-weight:bold;
            line-height: normal;
        }

        .date-author {
            font-size:16px;
            .date {
                font-weight:bold;
                color:#707070;
            }
            .author {
                text-transform:uppercase;
            }
        }
    }
}

/* Blog Listing Page */
.blog-list {
    .blog-column {
        margin-bottom:3.5rem;
        padding:1.5rem;

        h2 {
            font-weight:bold;
            font-size:1.5rem;
        }
    }
}

.development-carousel {
    .carousel-indicators-numbers {
        display:inline-block;
        position:relative;
        margin:0;

        div {
            display:inline-block;
            cursor:pointer;
            text-indent: 0;
            margin: 0 2px;
            width: 30px;
            height: 30px;
            border: none;
            border-radius: 0;
            line-height: 30px;
            color: #000;
            font-size:16px;
            text-align:center;
            background-color:transparent;
            transition: none;
                
            @include media-breakpoint-down(sm) {
                width: 20px;
                height: 20px;
                line-height: 20px;
                font-size:14px;
            }

            &.active, &:hover {
                margin: 0 2px;
                width: 30px;
                height: 30px;
                background-color:transparent;
                font-size:16px;
                color:$secondary;     
                font-weight:bold; 
                
                @include media-breakpoint-down(sm) {
                    width: 20px;
                    height: 20px;
                    font-size:14px;
                }
            }
        }
    }

    .progress-bar-container {
        position:absolute;
        top:30%;
        left:0px;
        z-index:15;
        width:66.666667%;
           
        @media (max-width: 1340px) {
            top:21%;
        }
           
        @media (max-width: 1150px) {
            top:16%;
        }
           
        @media (max-width: 986px) {
            top:18%;
        }
           
        @media (max-width: 767px) {
            top:15%;
        }
           
        @media (max-width: 767px) {
            top:15%;
        }
           
        @media (min-width:608px) and (max-width:766px) {
            top:15%;
        }
                
        @media (max-width: 574px) {
            top:9%;
            width:100%;
        }
    }
    
    .progress {
        float:left;
        width:300px;
        margin-right:20px;
        background-color:transparent;
        border-radius:0;
        height:7px;
                
        @include media-breakpoint-down(md) {
            width:200px;
        }
                
        @include media-breakpoint-down(sm) {
            width:100px;
        }
    
        &::after {
            content:'';
            flex: 1;
            height: 1px;
            background-color: #707070;
            margin-top:3px;
        }
    }

    .caption {
        margin-top:20%;
                
        @include media-breakpoint-down(sm) {
            margin-top:30%;
        }

        h2 {
            
            @media (max-width: 1530px) {
                font-size:20px;
            }
            @include media-breakpoint-down(sm) {
                font-size:18px;
            }
        }
    }

    .arrow-controls {
        position:absolute;
        bottom:0px;
        right: 33.333333%;
        width:95px;
        height:45px;
        z-index:1000;

        @include media-breakpoint-down(xs) {
            top:39.5%;
            bottom:auto;
            right:0;
            left:0;
            width:100%;
        }

        .carousel-control-prev {
            width:45px;
            height:45px;
            background-color:#888370;
            transition: opacity 0.3s ease-in;

            .carousel-control-prev-icon {
                background-image:url('/wp-content/themes/development-site/assets/images/arrow-prev-sm.png');
                width: 15px;
                height: 26px;
            }
        }
        
        .carousel-control-next {
            width:45px;
            height:45px;
            background-color:#888370;
            transition: opacity 0.3s ease-in;
            font-family: 'vc_grid_v1';

            .carousel-control-next-icon {
                background-image:url('/wp-content/themes/development-site/assets/images/arrow-next-sm.png');
                width: 15px;
                height: 26px;
            }
        }
    }
}

.development-slider {
    .development-copy {
        .copy {
            max-height: 463px;
            overflow-x:hidden;
            overflow-y:auto;
            
            @media (max-width: 1924px) {
                max-height: 400px;
            }
            
            @media (max-width: 1729px) {
                max-height: 392px;
            }
            
            @media (max-width: 1722px) {
                max-height: 300px;
            }
            
            @media (max-width: 1512px) {
                max-height: 300px;
            }
            
            @media (max-width: 1422px) {
                max-height: 287px;
            }
            
            @media (max-width: 1387px) {
                max-height: 270px;
            }
            
            @media (max-width: 1332px) {
                max-height: 250px;
            }
            
            @media (max-width: 1275px) {
                max-height: 235px;
            }
            
            @media (max-width: 1224px) {
                max-height: 225px;
            }
            
            @media (max-width: 1197px) {
                max-height: 188px;
            }
            
            @media (max-width: 1088px) {
                max-height: 167px;
            }
            
            @media (max-width: 1037px) {
                max-height: 145px;
            }
            
            @include media-breakpoint-down(md) {
                max-height: 132px;
            }
            
            @include media-breakpoint-down(sm) {
                max-height: 100%;
            }
        }
    }

    .slider-nav {
            
        @include media-breakpoint-down(sm) {
            margin-bottom:15px;
        }
    }
}



.homepage-carousel {
    .carousel-indicators-numbers {
        display:inline-block;
        position:relative;
        margin:0;

        div {
            display:inline-block;
            cursor:pointer;
            text-indent: 0;
            margin: 0 2px;
            width: 30px;
            height: 30px;
            border: none;
            border-radius: 0;
            line-height: 30px;
            color: #000;
            font-size:16px;
            text-align:center;
            background-color:transparent;
            transition: none;
                
            @include media-breakpoint-down(sm) {
                width: 20px;
                height: 20px;
                line-height: 20px;
                font-size:14px;
            }

            &.active, &:hover {
                margin: 0 2px;
                width: 30px;
                height: 30px;
                background-color:transparent;
                font-size:16px;
                color:$secondary;     
                font-weight:bold; 
                
                @include media-breakpoint-down(sm) {
                    width: 20px;
                    height: 20px;
                    font-size:14px;
                }
            }
        }
    }

    .carousel-inner {
        .carousel-item {
            .image-container {
                position:relative;
                &::before {
                    content:'';
                    position: absolute;
                    width: 100%;
                    height: 50%;
                    left: 0;
                    bottom: 0;
                    background: linear-gradient(to top, #000, transparent);
                    z-index: 2;
                }
            }
        }
    }

    .progress-bar-container {
        position:absolute;
        width:50%;
        right:0;
        top:77px;
        z-index:15;
                
        @include media-breakpoint-down(lg) {
            top:55px;
        }
                
        @include media-breakpoint-down(sm) {
            top:auto;
            bottom:30px;
            left:0;
            right:auto;
            width:75%;
        }
                
        @include media-breakpoint-down(xs) {
            width:100%;
        }
    }
    
    .progress {
        float:left;
        width:50%;
        margin-right:20px;
        background-color:transparent;
        border-radius:0;
        height:7px;
                
        @include media-breakpoint-down(md) {
            width:138px;
        }
                
        @include media-breakpoint-down(sm) {
            width:150px;
        }
                
        @include media-breakpoint-down(xs) {
            width:180px;
        }
    
        &::after {
            content:'';
            flex: 1;
            height: 1px;
            background-color: #707070;
            margin-top:3px;
        }
    }

    .title {
        position:absolute;
        bottom:0px;
        left:0px; 
        padding-left:120px;
        padding-right:120px;
        padding-bottom:80px;
        padding-top:80px;
        color:#fff;
        width:100%;
        z-index: 3;
                
        @include media-breakpoint-down(lg) {
            padding-left:50px;
            padding-bottom:50px;
            padding-right:50px;
        }
                
        @include media-breakpoint-down(md) {
            padding-left:50px;
            padding-bottom:50px;
            padding-right:10px;
        }
                
        @include media-breakpoint-down(sm) {
            padding-left:30px;
            padding-right:5px;
        }

        h2 {
            font-size:38px;
            line-height:normal;
            margin-bottom:0;
                
            @include media-breakpoint-down(md) {
                font-size:28px;
            }
        }
    }

    .caption {
        padding-top:10rem;
        padding-bottom:5rem;
        line-height: 1.7;
        
        @include media-breakpoint-down(lg) {
            padding-top:8rem;
            padding-bottom:5rem;
        }

        @include media-breakpoint-down(sm) {
            padding-top:3rem;
            padding-bottom:5rem;
        }

        h2 {
            
            @media (max-width: 1530px) {
                font-size:20px;
            }
            @include media-breakpoint-down(sm) {
                font-size:18px;
            }
        }
    }

    .arrow-controls {
        position:absolute;
        bottom:0px;
        right:50%;
        width:95px;
        height:45px;
        z-index:1000;

        @include media-breakpoint-down(sm) {
            top:25%;
            bottom:auto;
            right:0;
            left:0;
            width:100%;
        }

        .carousel-control-prev {
            width:45px;
            height:45px;
            background-color:#888370;
            transition: opacity 0.3s ease-in;

            .carousel-control-prev-icon {
                background-image:url('/wp-content/themes/development-site/assets/images/arrow-prev-sm.png');
                width: 15px;
                height: 26px;
            }
        }
        
        .carousel-control-next {
            width:45px;
            height:45px;
            background-color:#888370;
            transition: opacity 0.3s ease-in;
            font-family: 'vc_grid_v1';

            .carousel-control-next-icon {
                background-image:url('/wp-content/themes/development-site/assets/images/arrow-next-sm.png');
                width: 15px;
                height: 26px;
            }
        }
    }
}