// Home Slider
.home-slider {
    height:100vh;

    @include media-breakpoint-down(md) {
        height:500px !important;
    }

    .vc_active{
        background-color: white !important;
    }

    img {
        object-fit:cover;
        height:100vh !important;

        @include media-breakpoint-down(md) {
            height:500px !important;
        }
    }

    &.slider-overlay{
        background-color: $overlay-bg;
        .vc_carousel-inner {
            opacity: 0.41;
        }
    }

    .home-slider-content{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        z-index: 10;
        color:#fff !important;
        background-color:transparent;
        text-align:center;

        .homeslidetitle > h1, .h1, h2 {
            color:#fff !important;
            font-size:24px;
            font-weight:bold;
            margin-bottom:20px;
            @include media-breakpoint-down(sm) {
                font-size:18px !important;
            }
        }

        .sub-heading {
            font-size:22px;
            line-height:normal;
            @include media-breakpoint-down(sm) {
                font-size:16px !important;
            }
        }
    }

    .homepage-slider-caption {
        .vc_column-inner {
            > .wpb_wrapper {
                padding-left: 20%;
                padding-right: 20%;

                @include media-breakpoint-down(md) {
                    padding-left: 15%;
                    padding-right: 15%;
                }

                @include media-breakpoint-down(sm) {
                    padding-left: 10%;
                    padding-right: 10%;
                }
            }
        }
    }

    .vc_images_carousel .vc_carousel-indicators li {
        border: 1px solid #fff;
        background-color:transparent;
        margin-left:5px;
        margin-right:5px;
    }
    
    .phone-number{
        position:absolute; 
        bottom:2.5rem; 
        left:3rem; 
        z-index: 10; 
        color:#fff; 
        background-color:transparent;
    }
}