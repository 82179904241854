// default font size
$font-size-base: 0.875rem !default; // `14px`
$line-height-base: 1.5 !default;

// default site font color
$gray-900: #373738 !default;
$gray-600: #717171 !default;

// primary and secondary site colors
$primary: #4D4F64;
$secondary: #888370;

// font family
$font-family-sans-serif: 'Roboto','Helvetica Neue','Open Sans',Helvetica,Arial,sans-serif;

$link-color: $gray-900 !default;

// buttons variable changes
$btn-border-radius:           0 !default;
$btn-border-radius-lg:        0 !default;
$btn-border-radius-sm:        0 !default;

// inputs 
$input-border-radius:0;
$input-border-radius-sm: 0;
$input-border-radius-lg: 0;
$input-border-color: #707070;
$input-bg: #fff;
$input-focus-border-color: #707070;

$input-btn-focus-box-shadow:  0 0 0 .2rem rgba(#707070, .25) !default;

// breadcrumbs
$breadcrumb-divider: ">" !default;

// tooltips
$tooltip-bg:                  #4D4F64 !default;
$tooltip-opacity:             .9 !default;
$tooltip-arrow-color:         #4D4F64 !default;

//overlay
$overlay-bg: #4D4F64;


$carousel-transition-duration:       .75s !default;