
.btn {
    &.btn-dark {
        background-color:$gray-900;

        @include hover-focus {
            background-color:darken($secondary, 15%);
            border-color:$secondary;
        }
    }

    &.btn-transparent {
        background-color:transparent;
        color:#fff;
        text-decoration:underline;

        &.no-pl {
            color:$gray-900;
            padding-left:0px;
            transition: padding .15s ease-in;
            @include hover-focus {
                padding-left:0.75rem;
                background-color:$primary;
                color:#fff;
                border-color:$primary;
            } 
        }

        @include hover-focus {
            background-color:darken($primary, 15%);
            border-color:$primary;
            text-decoration:none;
        }        
    }

    &.btn-white {
        background-color:#fff;
        border-color:#fff;
        color:$gray-900;

        &.no-pl {
            padding-left:0px;
            transition: padding .15s ease-in;
            @include hover-focus {
                padding-left:0.75rem;
            } 
        }

        &.btn-home {
            width:auto;
            margin-top:50px;
            font-size:16px;
            padding:0.85rem 2.5rem;
            line-height:16px;

            @include media-breakpoint-down(sm) {            
                margin-top: 25px;
                font-size: 12px;
                line-height:12px;
            }

            @include hover-focus {
                background-color:darken($primary, 15%);
                color:#fff;
                border-color:$primary;
            }  
        }

        @include hover-focus {
            background-color:$primary;
            color:#fff;
            border-color:$primary;
        }        
    }

    &.btn-white-w-border {
        background-color:#fff;
        border-color:$secondary;
        color:$gray-900;

        @include hover-focus {
            background-color:$secondary;
            color:#fff;
            border-color:$secondary;
        }        
    }

    &.btn-home-phone {
        width:180px;
        background-color:transparent;
        border-color:#CCC7B3;
        color:#fff;
        letter-spacing: 1px;
        padding:0.85rem 2.5rem;
        font-size:14px;
        font-weight:bold;

        @include hover-focus {
            background-color:darken($primary, 15%);
            border-color:#CCC7B3;
        }     
    }
}

.btn-lg {
    font-size:0.85rem;
    padding:0.85rem 3rem;
}